import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((_theme: Theme) => {
  return createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      transform: 'none',
    },
    loader: {
      position: 'absolute',
      right: 34,
    },
    autocompletePaper: {
      maxHeight: 242,
    },
    autocompleteInput: {
      paddingRight: 54,
    },
    boldOption: {
      fontWeight: 'bold',
    },
  });
});
