import Cookies from 'universal-cookie';

export const SAM_JWT_ACCESS_TOKEN = 'SAM_JWT_ACCESS_TOKEN';
export const SAM_JWT_ACCESS_UID = 'SAM_JWT_ACCESS_UID';
export const SELECTED_DATABASE = 'selected_database';
export const IMPACT_FEATURE_FLAGS = 'impactFeatureFlags';

export const DEFAULT_THEME_COOKIE_OBJECT = '{ "isDarkModeEnabled": true }';

export const cookies = new Cookies();

export const getCookie = (name: string) => {
  const value = new RegExp(`(^|;) ?${name}=([^;]*)(;|$)`).exec(document.cookie);

  return value !== null ? value[2] : null;
};

export const setCookie = (cookie: string) => {
  const hostname = window.location.hostname;
  const domain = hostname.substring(hostname.lastIndexOf('.', hostname.lastIndexOf('.') - 1) + 1);
  document.cookie = `${cookie};path=/;domain=${domain}`;
};
