import { api } from 'constants/API';
import { AppRoute, ForceRedirectionUrl } from 'constants/AppRoute';

import { Button, Divider, Typography } from '@material-ui/core';
import axios from 'axios';
import cn from 'clsx';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { TrackVirtualPageView } from 'app/components/common/TrackVirtualPageView/TrackVirtualPageView';
import { LogoutRequestBody } from 'app/components/containers/layout/HeaderLandingPage/Profile/ProfileMenu/ProfileMenuBody';
import { actions as oktaActions } from 'app/components/pages/OktaLoginPage/slice';
import { actions } from 'app/containers/PrivateRoute/slice';
import { domainName } from 'helpers/domain';
import { SAM_JWT_ACCESS_TOKEN, SELECTED_DATABASE } from 'utils/cookie';

import { useStyles } from '../ForbiddenPage/styles';

export function UnauthorizedPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const forceRedirectionUrl = window.sessionStorage.getItem(ForceRedirectionUrl);
  const dispatch = useDispatch();

  const logoutRequestData: LogoutRequestBody = {
    logoutPageUrl: window.location.toString(),
    source: 'Logout Button',
    domain: domainName(),
  };

  const handleConfirm = () => {
    dispatch(oktaActions.clearUserInfoFromOkta());
    document.cookie = `${SAM_JWT_ACCESS_TOKEN}= ; Secure; Domain=${domainName()}; path=/;`;
    document.cookie = `${SELECTED_DATABASE}= ; Domain=${domainName()}; path=/;`;
    axios.delete(api.logout, { data: logoutRequestData }).finally(() => {
      window.location.href = `${process.env.REACT_APP_IHS_AUTH_URL}/clearsession`;
    });
  };

  useEffect(() => {
    if (forceRedirectionUrl) {
      if (window.location.pathname.includes(AppRoute.OKTA_LOGIN) || window.location.pathname.includes('code=')) {
        window.location.href = `${window.location.origin}${AppRoute.OKTA_LOGIN_PAGE}${window.location.search}`;
      } else {
        window.location.href = `${process.env.REACT_APP_IHS_AUTH_URL}/login?callingUrl=${encodeURIComponent(
          forceRedirectionUrl,
        )}`;
      }
      window.sessionStorage.removeItem(ForceRedirectionUrl);
    }
    dispatch(actions.clearUserInfo());
  }, [dispatch, forceRedirectionUrl]);

  return (
    <>
      <Helmet>
        <title>{t(`UNAUTHORIZED.META_TITLE`)}</title>
      </Helmet>
      <TrackVirtualPageView pageTitle={t(`UNAUTHORIZED.META_TITLE`)} />
      <div className={classes.wrapper}>
        <div className={classes.card}>
          <div className={classes.text}>
            <Typography className={classes.title}>{t(`UNAUTHORIZED.TITLE`)}</Typography>
            <Divider className={classes.divider} />
            <div className={classes.infoWrapper}>
              <Typography component="span" className={classes.info}>
                {t(`UNAUTHORIZED.DESCRIPTION`)}
              </Typography>
            </div>
          </div>
          <div className={cn(classes.infoWrapper, classes.buttonContainer)} onClick={handleConfirm}>
            <Button className={classes.button}>{t('UNAUTHORIZED.CONFIRM')}</Button>
          </div>
        </div>
      </div>
    </>
  );
}
