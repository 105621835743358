import { ThemeMode } from 'impact-react-components';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

export interface StyleProps {
  isPriceFeed: boolean;
  borderBottomSpace: number;
}

export const useStyles = makeStyles((theme) => {
  const { type, text } = theme.palette;

  return createStyles({
    headerItem: {
      display: 'flex',
      height: '28px',
      alignItems: 'center',
      padding: '8px',
      cursor: 'pointer',
      position: 'relative',
    },
    basicColors: {
      color: ({ isPriceFeed }: StyleProps) =>
        type === ThemeMode.DARK
          ? isPriceFeed && type === ThemeMode.DARK
            ? colors.baseGrey20
            : colors.baseGrey05
          : colors.grey40,
      '& .label': {
        color: ({ isPriceFeed }: StyleProps) => (isPriceFeed ? colors.baseGrey20 : colors.baseGrey10),
        '&:hover': {
          color: ({ isPriceFeed }: StyleProps) => (isPriceFeed ? colors.baseGrey20 : colors.baseGrey10),
        },
      },
    },
    readOnly: {
      cursor: 'default',
    },
    disabled: {
      cursor: 'default',
      '& $label': {
        color: text.secondary,
        '&:hover': {
          color: text.secondary,
        },
      },
    },

    priceFeedLabel: {
      color: 'red',
      lineHeight: '15px',
    },
    label: {
      fontFamily: 'Akkurat, sans serif',
      fontStyle: 'normal',
      FontWeight: 400,
      fontSize: '14px',
      lineHeight: '21px',
      '&:hover': {
        color: ({ isPriceFeed }: StyleProps) =>
          type === ThemeMode.DARK
            ? isPriceFeed && type === ThemeMode.DARK
              ? colors.baseGrey20
              : colors.baseGrey05
            : colors.grey90,
      },
    },

    active: {
      color: type === ThemeMode.DARK ? colors.grey61 : colors.baseGrey20,
    },
    arrowIcon: {
      fontSize: '24px !important',
      fontWeight: 200,
      marginRight: -6,
      verticalAlign: 'middle',
    },
    animatedBorder: {
      background: colors.primary,
      width: '100%',
      height: '2px',
      position: 'absolute',
      bottom: ({ borderBottomSpace }: StyleProps) => borderBottomSpace,
      left: 0,
      animation: '$showHighlightBar 0.25s',
    },
    '@keyframes showHighlightBar': {
      '0%': { transform: 'scale(0, 1)' },
      '100%': { transform: 'scale(1, 1)' },
    },
  });
});
