import { ThemeMode } from 'impact-react-components';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

const indent = '16px';

export const useStyles = makeStyles((theme) => {
  const { type } = theme.palette;
  const { fontWeightRegular } = theme.typography;

  return createStyles({
    menuItem: {
      display: 'flex',
      justifyContent: 'space-between',
      height: 36,
      padding: `0 ${indent}`,
      fontWeight: fontWeightRegular as number,
    },
    copy: {
      color: colors.primary,
      cursor: 'pointer',
      marginLeft: indent,
    },
    linkItem: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      '&:hover': {
        color: `${type === ThemeMode.DARK ? colors.baseGrey10 : colors.baseGrey80}!important`,
      },
    },
  });
});
