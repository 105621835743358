import { ThemeMode } from 'impact-react-components';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

export const useStyles = makeStyles((theme) => {
  const { type } = theme.palette;

  return createStyles({
    priceFeedLabel: {
      lineHeigh: '15px',
      color: () => (type === ThemeMode.DARK ? colors.smokeScreen : colors.grey40),
    },
    divider: {
      margin: '2px 8px',
      height: '16px',
      backgroundColor: colors.ebony,
    },
  });
});
