import { PayloadAction } from '@reduxjs/toolkit';
import { changeDefaultDatabaseInHeavy, fetchUserInfo, updateAccessCookies, updateUserStatus } from 'api/user';
import axios, { AxiosResponse } from 'axios';
import { call, cancelled, put, select, takeLatest } from 'redux-saga/effects';
import Cookies from 'universal-cookie';

import { actions as scenariosActions } from 'app/components/containers/layout/ConfigTabs/Scenario/slice';
import { EDatabaseName } from 'helpers/constants';
import { LAST_TOKEN_REFRESH_DATE, shouldRefreshAccessToken } from 'helpers/cookies';
import { domainName } from 'helpers/domain';
import { UserInfo } from 'types/UserInfo';
import { getErrorMessage } from 'utils/error';

import { userAvailableDatabases, userSelectedDatabase } from './selectors';
import { actions } from './slice';
import { isUserFromIframBasedOnWindowObject, setSelectedDatabaseByOrigin } from './utils';

function* refreshAccessCookies() {
  const cancelToken = axios.CancelToken.source();
  try {
    const cookies = new Cookies();
    const lastTokenRefreshDate = cookies.get(LAST_TOKEN_REFRESH_DATE);
    if (
      (!lastTokenRefreshDate && !isUserFromIframBasedOnWindowObject) ||
      (lastTokenRefreshDate && shouldRefreshAccessToken(lastTokenRefreshDate) && !isUserFromIframBasedOnWindowObject)
    ) {
      yield call(
        updateAccessCookies,
        { shouldRefreshToken: !!lastTokenRefreshDate, domain: domainName() },
        cancelToken,
      );
    }
  } catch (error) {
    // Save all error to read http code
    yield put(actions.userInfoError(error));
  }
}

function* getUserInfo() {
  try {
    const entitlements: AxiosResponse<UserInfo> = yield call(fetchUserInfo);
    yield call(refreshAccessCookies);
    yield put(actions.userInfoLoaded(entitlements.data));
    const selectedDB: EDatabaseName = yield select(userSelectedDatabase);
    if (!selectedDB) yield call(setSelectedDatabase);
  } catch (error) {
    // Save all error to read http code
    yield put(actions.userInfoError(error));
  }
}

function* updateUserVisitedDates() {
  const cancelToken = axios.CancelToken.source();
  try {
    yield call(updateUserStatus);

    yield put(actions.userVisitedDatesUpdated());
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put(actions.userVisitedDatesError(errorMessage));
  } finally {
    const isCancelled: boolean = yield cancelled();
    if (isCancelled) {
      yield call(cancelToken.cancel);
    }
  }
}

function* setSelectedDatabase() {
  try {
    const selectedDB: EDatabaseName = yield select(userSelectedDatabase);
    const availableDB: EDatabaseName[] = yield select(userAvailableDatabases);

    const databaseSelection = setSelectedDatabaseByOrigin(selectedDB, availableDB);

    const cancelToken = axios.CancelToken.source();

    if (databaseSelection) {
      yield call(changeDefaultDatabaseInHeavy, databaseSelection, cancelToken);
      yield put(actions.setSelectedDatabase(databaseSelection));
    }
  } catch (error) {
    // Save all error to read http code
    yield put(actions.userInfoError(error));
  }
}

function* changeSelectedDatabaseInHeavy(action: PayloadAction<EDatabaseName>) {
  const cancelToken = axios.CancelToken.source();
  try {
    if (action.payload) yield call(changeDefaultDatabaseInHeavy, action.payload, cancelToken);
  } catch (error) {
    // Save all error to read http code
    yield put(actions.userInfoError(error));
  }
}

export function* userInfoSaga() {
  yield takeLatest(actions.loadUserInfo.type, getUserInfo);
  yield takeLatest(actions.setSelectedDatabase.type, changeSelectedDatabaseInHeavy);
  yield takeLatest(actions.updateUserVisitedDates.type, updateUserVisitedDates);
  yield takeLatest(scenariosActions.setDataForSaving.type, refreshAccessCookies);
  yield takeLatest(scenariosActions.scenarioLoaded.type, refreshAccessCookies);
  yield takeLatest(scenariosActions.resetScenario.type, refreshAccessCookies);
  yield takeLatest(scenariosActions.renameScenario.type, refreshAccessCookies);
  yield takeLatest(scenariosActions.deleteScenario.type, refreshAccessCookies);
  yield takeLatest(scenariosActions.copyScenario.type, refreshAccessCookies);
  yield takeLatest(scenariosActions.shareScenario.type, refreshAccessCookies);
}
