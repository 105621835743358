import { ThemeMode } from 'impact-react-components';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

export const useStyles = makeStyles((theme) => {
  const { type } = theme.palette;
  const { fontWeightRegular } = theme.typography;
  const isDarkTheme = type === ThemeMode.DARK;

  return createStyles({
    menuItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 10,
      width: 208,
      cursor: 'default',
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
    dataItem: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      overflowWrap: 'break-word',
    },
    label: {
      fontWeight: fontWeightRegular as number,
      color: isDarkTheme ? colors.baseGrey20 : colors.grey42,
    },
    selectStyle: {
      fontWeight: 400,
      color: isDarkTheme ? colors.grey12 : colors.grey54,
      cursor: 'default',
    },
    menuItemPadding: {
      paddingBottom: '0px',
      paddingTop: '4px',
    },
    textFieldBorder: {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiOutlinedInput-input': {
        backgroundColor: isDarkTheme ? 'iherit' : colors.baseGrey05,
      },
      '& .MuiInputLabel-outlined': {
        fontSize: '16px',
        marginLeft: '7px',
        color: 'gray',
        fontWeight: 400,
      },
    },
    rootMenuItem: {
      '&:hover, &.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: isDarkTheme ? colors.grey54 : colors.grey12,
        color: isDarkTheme ? colors.grey12 : colors.grey54,
      },
      fontSize: '16px',
      backgroundColor: isDarkTheme ? colors.grey56 : colors.baseGrey05,
      color: isDarkTheme ? colors.grey45 : colors.baseGrey35,
      fontFamily: '"Akkurat", Arial, sans-serif',
      fontWeight: 400,
      padding: '10px',
      cursor: 'default',
    },
    menuItemBorder: {
      border: '1px solid',
      overflowY: 'scroll',
      borderColor: isDarkTheme ? colors.grey56 : colors.grey15,
    },
    value: {
      fontWeight: fontWeightRegular as number,
      color: isDarkTheme ? colors.baseGrey10 : colors.grey51,
      paddingLeft: 6,
      overflowWrap: 'break-word',
    },
    profileLabel: {
      fontSize: '12px',
    },
    databaseLabel: {
      fontSize: '14px',
    },
    button: {
      boxShadow: 'none',
      borderRadius: 4,
      height: 32,
    },
    divider: {
      width: '100%',
      margin: theme.spacing(2, 0),
    },
  });
});
