import { api } from 'constants/API';

import axios from 'axios';
import Cookies from 'universal-cookie';

import { FieldInfo } from 'types/FieldInfo';
import { UserInfo } from 'types/UserInfo';
import { MapWidgetId } from 'types/Widget';
import { SAM_JWT_ACCESS_UID } from 'utils/cookie';

export enum EInteractionType {
  SHARE = 'share',
  DUPLICATE = 'duplicate',
  IMPORT = 'import',
  SAVE = 'save',
  ADD = 'add',
}

const dataLayer = (window as any).dataLayer || [];

export const trackUserInfo = async () => {
  try {
    const response = await axios.get<UserInfo>(api.getUserInfo);
    const userInfo = response.data;
    const internalDomains = ['impact', 'spglobal', 'ihsmarkit', 'ihs', 'epam', 'test.ciam'].map(
      (name) => `${name}.com`,
    );
    const uidCookie = new Cookies().get(SAM_JWT_ACCESS_UID);
    const userDomain = userInfo.userDetails.email.split('@')[1].toLowerCase();
    const isInternal = internalDomains.includes(userDomain);
    dataLayer.push({
      event: 'load',
      user: [
        {
          id: uidCookie || userInfo.userId, // OKTA UID or NIMS User ID
          organization: userInfo.userDetails.company,
          internal: isInternal,
        },
      ],
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('[WARN] fetching user info:', error);
  }
};

export const trackVirtualPageview = (pageTitle: string) => {
  const vPagePath = window.location.href;

  dataLayer.push({
    event: 'VirtualPageview',
    VPagePath: vPagePath,
    VPageTitle: pageTitle,
  });
};

const handleClick = (linkPath: string, linkText: string, eventName: string) => {
  const url = new URL(window.location.href);
  const linkUrl = `${url.protocol}//${url.host}${linkPath}`;
  dataLayer.push({
    event: eventName,
    link_text: linkText,
    link_url: linkUrl,
  });
};

export const headerNavClick = (linkPath: string, linkText?: string) => {
  if (linkPath) {
    const linkText: string | undefined = linkPath.split('%2F').pop()?.toUpperCase().replace(/-/g, ' ');
    handleClick(linkPath, linkText || '', 'header_nav_click');
  } else {
    handleClick(linkText ?? '', linkText || '', 'header_nav_click');
  }
};

export const predictMapClick = (uwi: string) => {
  dataLayer.push({
    event: 'esi_mapclick_predict',
    uwi,
  });
};

export const menuTabClick = (linkText: string, tabName: string) => {
  dataLayer.push({
    event: 'click_tab',
    link_text: linkText,
    tab_name: tabName,
  });
};

export const addWellToMapClick = () => {
  dataLayer.push({
    event: 'user_feature_click',
    feature_type: 'Well Planner features',
    feature_text: 'Add Well',
    location: 'Energy Studio Impact : Well Planner',
  });
};

export const landingPageDashboardClick = (dashboardName: string) => {
  dataLayer.push({
    event: 'esi_dashboard',
    interaction_type: 'open',
    dashboard_name: dashboardName,
    location: 'Energy Studio Impact - Landing Page',
  });
};

export const landingPageCreateNewDashboardClick = () => {
  dataLayer.push({
    event: 'esi_dashboard',
    interaction_type: 'create',
    dashboard_name: 'new',
    location: 'Energy Studio Impact - Landing Page',
  });
};

export const runScenarioClick = (uwi: string) => {
  dataLayer.push({
    event: 'esi_scenario_economics',
    interaction_type: 'Run',
    scenario_name: 'user_defined',
    uwi,
    location: 'Energy Studio Impact - Economics',
  });
};

export const resetScenarioClick = (uwi: string) => {
  dataLayer.push({
    event: 'esi_scenario_economics',
    interaction_type: 'Reset',
    scenario_name: 'user_defined',
    uwi,
    location: 'Energy Studio Impact - Economics',
  });
};

export const shapeFileDownloadClick = (country: string, fileName: string) => {
  dataLayer.push({
    event: 'esi_download',
    download_type: 'Shape File Download',
    location_name: `Shape File Download - ${country}`,
    file_extension: 'zip',
    download_value: fileName,
  });
};

export const notificationsSectionClick = (linkText: string) => {
  dataLayer.push({
    event: 'notifications',
    link_text: linkText,
  });
};

export const planPredictFiltersChange = (
  filterType: string,
  filterValue: FieldInfo['value'],
  filterLocation: string,
  mapWidgetId: MapWidgetId,
) => {
  dataLayer.push({
    event: 'esi_filter',
    filter_type: filterType,
    filter_value: filterValue,
    filter_location: filterLocation,
    location:
      mapWidgetId === MapWidgetId.PREDICT_MAP_WIDGET
        ? 'Energy Studio Impact : Production Prediction'
        : 'Energy Studio Impact : Well Planner',
  });
};

export const planPredictEconomicsControlPanelChange = (
  filterType: string,
  filterValue: string,
  filterLocation: string,
  mapWidgetId: MapWidgetId,
) => {
  if (
    [MapWidgetId.PREDICT_MAP_WIDGET, MapWidgetId.PLAN_MAP_WIDGET, MapWidgetId.ECONOMICS_MAP_WIDGET].includes(
      mapWidgetId,
    )
  ) {
    let location = '';

    if (mapWidgetId === MapWidgetId.PREDICT_MAP_WIDGET) {
      location = 'Energy Studio Impact : Production Prediction';
    }
    if (mapWidgetId === MapWidgetId.PLAN_MAP_WIDGET) {
      location = 'Energy Studio Impact : Well Planner';
    }
    if (mapWidgetId === MapWidgetId.ECONOMICS_MAP_WIDGET) {
      location = 'Energy Studio Impact : Economics';
    }

    dataLayer.push({
      event: 'esi_control_panel',
      filter_type: filterType,
      filter_value: filterValue,
      filter_location: filterLocation,
      location,
    });
  }
};

export const importDuplicateShareSaveFilePlanClick = (
  interactionType: EInteractionType,
  scenarioName: string,
  playDesignation: string,
  // productionCurve: string,
  active: boolean,
  lateralLength: number,
  proppantPerFt: number,
  proppantPerFluidRatio: number,
  nearestSameReservoirNeighborSpacing: number,
  reservoir: string,
) => {
  dataLayer.push({
    event: 'esi_scenario',
    interaction_type: interactionType,
    scenario_name: scenarioName,
    location: 'Energy Studio Impact – Well Planner',
    esi_scenario_metadata: {
      play_destination: playDesignation,
      geologic_province: playDesignation,
      basin: playDesignation,
      // production_curve: productionCurve,
      curve: active ? 'Actual' : 'False',
      lateral_lengh: String(lateralLength),
      proppant: String(proppantPerFt),
      proppant_fluid_ratio: String(proppantPerFluidRatio),
      spacing: String(nearestSameReservoirNeighborSpacing),
      reservoir,
    },
  });
};

export const downloadFilePlanClick = (
  playDesignation: string,
  // productionCurve: string,
  active: boolean,
  lateralLength: number,
  proppantPerFt: number,
  proppantPerFluidRatio: number,
  nearestSameReservoirNeighborSpacing: number,
  reservoir: string,
) => {
  dataLayer.push({
    event: 'esi_download_scenario',
    location_name: 'Energy Studio Impact : Well Planner',
    esi_download_scenario_metadata: {
      play_destination: playDesignation,
      geologic_province: playDesignation,
      basin: playDesignation,
      // production_curve: productionCurve,
      curve: active ? 'Actual' : 'False',
      lateral_lengh: String(lateralLength),
      proppant: String(proppantPerFt),
      proppant_fluid_ratio: String(proppantPerFluidRatio),
      spacing: String(nearestSameReservoirNeighborSpacing),
      reservoir,
    },
  });
};

export const importDuplicateShareSaveAddEconomicsClick = (interactionType: EInteractionType, scenarioName: string) => {
  dataLayer.push({
    event: 'esi_scenario_economics',
    interaction_type: interactionType,
    scenario_name: scenarioName,
    location: 'Energy Studio Impact - Economics',
  });
};

export const downloadFilePredict = (
  uwi: string,
  geologic_province: string,
  basin: string,
  production_curve: string,
  unit: string,
  type: string,
  curve: string,
) => {
  dataLayer.push({
    event: 'esi_download_predict',
    location_name: 'Energy Studio Impact : Production Prediction',
    esi_download_predict_metadata: {
      uwi,
      geologic_province,
      basin,
      production_curve,
      unit,
      type,
      curve,
    },
  });
};

export const documentationSideNavClick = (linkText: string, linkUrl: string) => {
  dataLayer.push({
    event: 'side_nav_click',
    link_text: linkText,
    link_url: linkUrl,
  });
};

export const documentationVideoPlayClick = (videoTitle: string) => {
  dataLayer.push({
    event: 'esi_video',
    interaction_type: 'play',
    video_title: videoTitle,
    location_name: 'Energy Studio Impact : Documentation',
  });
};

export const documentationDownloadClick = (downloadType: string, downloadValue: string) => {
  dataLayer.push({
    event: 'esi_download_documentation',
    download_type: downloadType,
    download_value: downloadValue,
    location_name: 'Energy Studio Impact : Documentation',
  });
};

export const documentationSearchFire = (searchTerm: string) => {
  dataLayer.push({
    event: 'esi_search',
    search_term: searchTerm,
  });
};
