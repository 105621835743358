import { createStyles, makeStyles } from '@material-ui/core/styles';

import { HEADER_HEIGHT } from './constants';

export const useStyles = makeStyles(() =>
  createStyles({
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: `calc(100vh - ${HEADER_HEIGHT}px)`,
      overflowY: ({ overflowYHidden }: { overflowYHidden: boolean }) => (overflowYHidden ? 'hidden' : 'auto'),
    },
  }),
);
