import { AppRoute } from 'constants/AppRoute';
import { TestLocators } from 'constants/Locators';

import { Container } from '@material-ui/core';
import { ReactElement } from 'react';

import { HeaderLandingPage } from '../HeaderLandingPage';
import { useStyles } from './styles';

export interface MainLayoutProps {
  children: ReactElement;
  testId?: TestLocators;
  path?: string;
}

export function MainLayout({ children, testId, path }: MainLayoutProps) {
  const overflowYHidden = !!path?.includes(AppRoute.ECONOMICS);
  const classes = useStyles({ overflowYHidden });

  return (
    <>
      <HeaderLandingPage />
      <main className={classes.mainContainer}>
        <Container maxWidth={false} data-testid={testId} disableGutters>
          {children}
        </Container>
      </main>
    </>
  );
}
