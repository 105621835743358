import { useEffect } from 'react';

import { trackVirtualPageview as pageView } from 'common/analytics';

interface IProps {
  pageTitle: string;
}

export const TrackVirtualPageView = ({ pageTitle }: IProps) => {
  useEffect(() => {
    pageView(pageTitle);
  }, [pageTitle]);

  return <></>;
};
