import { TestLocators } from 'constants/Locators';

import { Button, Divider, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cn from 'clsx';
import { CustomLink } from 'impact-react-components';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { TrackVirtualPageView } from 'app/components/common/TrackVirtualPageView/TrackVirtualPageView';
import { selectErrorMessage } from 'app/containers/PrivateRoute/selectors';
import { getCallingUrl } from 'utils/routes';

import { Accordion } from './Accordion';
import { Details } from './Details';
import { useStyles } from './styles';
import { Summary } from './Summary';

export function ErrorPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const errorMessage = useSelector(selectErrorMessage);
  const [expanded, setExpanded] = useState(false);
  const handleClose = () => {
    window.location.href = getCallingUrl();
  };
  const toggleExpanded = () => setExpanded(!expanded);

  if (!errorMessage) {
    handleClose();
  }

  return (
    <>
      <Helmet>
        <title>{t('ERROR.META_TITLE')}</title>
      </Helmet>
      <TrackVirtualPageView pageTitle={t('ERROR.META_TITLE')} />
      <div className={classes.wrapper}>
        <div className={classes.card}>
          <div className={classes.text}>
            <Typography className={classes.title}>{t('ERROR.TITLE')}</Typography>
            <Divider className={classes.divider} />
            <div className={classes.infoWrapper}>
              <Typography component="span" className={classes.info} data-testid={TestLocators.NoAccess_Msge}>
                {t('ERROR.DESCRIPTION')}
              </Typography>
            </div>
            <div className={classes.infoWrapper}>
              <Typography component="span" className={classes.info} data-testid={TestLocators.NoAccess_Contact_Msge}>
                {t('ERROR.SUB_DESCRIPTION.PHONE_TEXT')}
              </Typography>
              <CustomLink
                className={cn(classes.info, classes.link)}
                href={`tel:${t('ERROR.SUB_DESCRIPTION.PHONE_LINK')}`}
                text={t('ERROR.SUB_DESCRIPTION.PHONE_LINK')}
                data-testid={TestLocators.NoAccess_Phone}
              />
              <Typography component="span" className={classes.info} data-testid={TestLocators.NoAccess_Or_Msge}>
                {t('ERROR.SUB_DESCRIPTION.EMAIL_TEXT')}
              </Typography>
              <CustomLink
                className={cn(classes.info, classes.link)}
                href={`mailto:${t('ERROR.SUB_DESCRIPTION.EMAIL_LINK')}`}
                text={t('ERROR.SUB_DESCRIPTION.EMAIL_LINK')}
                data-testid={TestLocators.NoAccess_Email}
              />
            </div>
            {errorMessage && (
              <Accordion square expanded={expanded} onChange={toggleExpanded}>
                <Summary
                  aria-controls="error-content"
                  expandIcon={<ExpandMoreIcon />}
                  data-testid={TestLocators.NoAccess_Expand_Summary}
                >
                  <Typography data-testid={`${TestLocators.NoAccess_Error}_${expanded ? 'Open' : 'Closed'}`}>
                    {t('ERROR.EXPAND_TITLE')}
                  </Typography>
                </Summary>
                <Details>{errorMessage}</Details>
              </Accordion>
            )}
          </div>
          <div className={cn(classes.infoWrapper, classes.buttonContainer)}>
            <Button className={classes.button} onClick={handleClose}>
              {t('COMMON.CLOSE')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
